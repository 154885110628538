<template>
  <div>
    <v-dialog
      v-model="getDialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      max-width="464px"
      content-class="title-close log-history"
    >
      <v-card>
        <v-card-title>
          <span>{{ $t("b_list_all.table_log_history") }}</span>
          <div class="button-close">
            <img @click="close" src="@/assets/images/dialog/close.svg" class="button-close_close"/>
            <img @click="close" src="@/assets/images/dialog/close_active.svg" class="button-close_active"/>
          </div>
        </v-card-title>
        <v-card-text>
            <v-list-item
              v-for="(item, index) in historyLogList"
              :key="index"
            >
              <v-list-item-content>
                <div class="item-content-header">
                  <div class="item-content-header-left">{{ item.user.name }}</div>
                  <div class="item-content-header-right">{{ item.date }}</div>
                </div>
                <div class="item-content" :class="`${item.color}-color`">{{ item.status }}</div>
              </v-list-item-content>
            </v-list-item>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props     : {
    dialog  : {
      type   : Boolean,
      default: false
    },
    contents: {
      type   : Array,
      default: () => []
    },
    historyLogList: {
      type   : Array,
      default: () => []
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      }
    }
  },
  methods : {
    close() {
      this.$emit("close");
    },
    submit() {
      this.$emit("submit");
    },
    handleClickOutSite() {
      this.$emit("close");
    },
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
